
import Vue from 'vue';
import { Watch } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';
import axios from '../http/axios';
import Site from '../store/models/Site';

const props = Vue.extend({
  props: ['multiple', 'disabled', 'errorMessages', 'clearable', 'value', 'label', 'hideDetails'],
});

@Component
export default class SiteSearch extends mixins(Vue, props) {
  descriptionLimit = 60;

  items: Site[] = [];

  isLoading = false;

  search = null;

  mounted() {
    this.createNewValueItems(this.value);
  }

  querySelections(query: string) {
    this.isLoading = true;

    return axios.get(`/sites/search/${query}`).then((response) => {
      this.isLoading = false;
      this.items = response.data;
    });
  }

  formatResult(site: Site) {
    return `${site.customerId} ${site.name}`;
  }

  updateValue(value: Site | Site[]) {
    this.$emit('input', value);
  }

  createNewValueItems(newValue: Site | Site[]) {
    const addElementIfNeeded = (element: Site) => {
      const contains = this.items.find((site) => site._id === element._id);
      if (!contains) this.items.push(element);
    };
    if (newValue) {
      // Tableau si valeurs multiples
      let toAdd = [];
      if (!Array.isArray(newValue)) toAdd.push(newValue);
      else toAdd = newValue;
      toAdd.forEach((e) => addElementIfNeeded(e));
    }
  }

  @Watch('search')
  wSearch(newValue: string) {
    newValue && this.querySelections(newValue);
  }

  @Watch('value')
  wValue(newValue: Site | Site[]) {
    this.createNewValueItems(newValue);
  }
}
