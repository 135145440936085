
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import Collection from '@shared/store/models/Collection';

const props = Vue.extend({
  props: ['label', 'collection', 'multiple', 'hint', 'value', 'effects', 'disabled'],
});

@Component
export default class MpSelect extends mixins(Vue, props) {
  get arrayValue() {
    if (!this.value) return [];
    if (this.multiple) return this.value.split(',');
    return this.value;
  }

  get _collection(): Collection | null {
    if (!this.collection) return null;
    return this.$store.getters.getCollectionById(this.collection);
  }

  change(value: string | string[]) {
    if (Array.isArray(value)) this.$emit('input', value.join(','));
    else this.$emit('input', value);
  }
}
