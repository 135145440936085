
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import slugify from 'slugify';
// import actions from '@shared/store/actions';
import FilePreview from '@shared/store/models/FilePreview';
import CommunicationFile from '@shared/store/models/CommunicationFile';
import HttpMixin from '@shared/http/http.mixin';

const props = Vue.extend({
  props: ['value', 'orientation', 'ratio', 'validationErrors'],
});

@Component
export default class CommunicationFilePreview extends mixins(Vue, HttpMixin, props) {
  acceptedVideos = ['video/mp4'];

  acceptedImages = ['image/png', 'image/gif', 'image/jpeg', 'image/svg+xml'];

  restorableValue: CommunicationFile | null = null;

  preview: FilePreview | null = null;

  file: File | null = null;

  uploading = false;

  progress = 0;

  upload() {
    if (this.file) {
      const formData = new FormData();
      const rand = Math.random()
        .toString(36)
        .substr(2, 9);
      const slug = slugify(this.file.name, { remove: /[^\w.]/ });
      const filename = `public/comms/${rand}_${slug}`;
      formData.append('filename', filename);
      formData.append('mtime', new Date().toISOString());
      formData.append('file', this.file);
      this.uploading = true;
      this.http
        .uploadFileRD(formData, (percent: number) => {
          this.progress = percent;
        })
        .then(() => {
          if (this.file) {
            this.updateValue({
              size: this.file.size,
              filename: filename.slice('public/'.length),
              type: this.file.type,
            });
          }
          this.file = null;
          this.preview = null;
        })
        .finally(() => {
          this.uploading = false;
        });
    }
  }

  change(file: File | null) {
    if (file instanceof File) {
      this.preview = {
        url: URL.createObjectURL(file),
        type: file.type,
        size: file.size,
      };
    } else this.preview = null;
  }

  remove() {
    this.restorableValue = this.value;
    this.updateValue(null);
  }

  reset() {
    this.preview = null;
    this.file = null;
    if (this.restorableValue) this.updateValue(this.restorableValue);
  }

  updateValue(value: CommunicationFile | null) {
    this.$emit('input', value);
  }

  get usedValue() {
    if (this.preview) return this.preview;
    return this.value;
  }

  get isVideo() {
    if (this.usedValue) return this.acceptedVideos.indexOf(this.usedValue.type) !== -1;
    return false;
  }

  get isImage() {
    if (this.usedValue) return this.acceptedImages.indexOf(this.usedValue.type) !== -1;
    return false;
  }

  get previewUrl() {
    if (this.preview) return this.preview.url;
    if (this.value) return `${process.env.VUE_APP_REPOSITORY_URL}/${this.value.filename}`;
    return null;
  }

  get filesize() {
    if (this.usedValue) return Math.round((this.usedValue.size / 1000000) * 10) / 10;
    return null;
  }
}
