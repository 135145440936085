
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';

const props = Vue.extend({
  props: ['value'],
});

@Component
export default class CommunicationsTableFilters extends mixins(Vue, props) {}
