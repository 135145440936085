export default {
  LOADING: 'loading',
  USER_SET: 'user',
  COLLECTIONS_SET: 'collections_set',
  FORMS_SET: 'forms_set',
  SITE_SELECT: 'site_select',
  LISTS_SET: 'lists_set',
  LIST_SELECT: 'list_select',
  LIST_ADD_OR_UPDATE: 'list_add_update',
  COMMUNICATION_SELECT: 'comm_select',
  COMMUNICATIONS_SET: 'communications_set',
  // COMMUNICATION_ADD_OR_UPDATE: 'communication_add_update',
  COMMUNICATIONS_SHOW_ONLY_DISPLAYED_SET: 'comms_only_displayed',
  THEME_SET: 'theme_set',
};
