import moment from 'moment';
import CommunicationFile from './CommunicationFile';
import Site from './Site';

export default class Communication {
  _id?: string;

  name = '';

  fileV: CommunicationFile | null = null;

  fileC: CommunicationFile | null = null;

  fileH: CommunicationFile | null = null;

  duration = 7;

  mandatory = false;

  marketing = false;

  global = false;

  disabled: string[] = [];

  startAt = moment().toISOString();

  endAt = moment()
    .add(1, 'week')
    .toISOString();

  zones: string[] = [];

  lists: string[] = [];

  sites: Site[] | string[] = [];
}
