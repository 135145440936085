import Vue from 'vue';
import Component from 'vue-class-component';
import { SnackOptions, SnackType } from '../app.constants';

@Component
export default class SnackMixin extends Vue {
  snack(opts: SnackOptions) {
    this.$root.$emit('snack', opts);
  }

  accessDeniedSnack() {
    return this.snack({
      type: SnackType.ERROR,
      text: "Vous n'avez pas l'autorisation pour accéder à cette ressource",
      timeout: 7000,
    });
  }
}
