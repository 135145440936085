
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import moment from 'moment';

const props = Vue.extend({
  props: ['value', 'min', 'max'],
});

@Component
export default class DatePicker extends mixins(Vue, props) {
  input(iso: string) {
    this.$emit('input', moment(iso).toISOString(true));
  }

  isoToDateOnly(iso: string) {
    if (iso) return iso.substr(0, 10);
    return '';
  }
}
