
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import HttpMixin from '@shared/http/http.mixin';
import SiteSearch from '@shared/components/SiteSearch.vue';
import CommunicationsTable from '@shared/components/CommunicationsTable.vue';
import ListsManagerDialog from '@shared/components/ListsManagerDialog.vue';
import mutations from '@shared/store/mutations';
import Site from '@shared/store/models/Site';
import List from '@shared/store/models/List';
import User from '@shared/store/models/User';
import Communication from '@shared/store/models/Communication';

@Component({
  components: { SiteSearch, CommunicationsTable, ListsManagerDialog },
})
export default class Communications extends mixins(Vue, HttpMixin) {
  communications: Communication[] = [];

  async mounted() {
    // Chargement choix des listes & sites
    this.resetTitle();
    if (!this.filtered && this.user.sites.length === 1) {
      await this.selectSite(this.user.sites[0]);
    } else await this.load();
  }

  /* get communications() {
    return this.$store.state.communications;
  } */

  get filtered() {
    return this.site || this.list;
  }

  get isMarketing() {
    return this.$store.getters.isMarketing;
  }

  get isAdmin() {
    return this.$store.getters.isAdmin;
  }

  get lists() {
    return this.$store.state.lists;
  }

  get user(): User {
    return this.$store.state.user;
  }

  get site() {
    return this.$store.state.selectedSite;
  }

  get list() {
    return this.$store.state.selectedList;
  }

  async selectSite(site: Site) {
    this.$store.commit(mutations.SITE_SELECT, site);
    this.$store.commit(mutations.LIST_SELECT, null);
    return this.load();
  }

  async selectList(list: List) {
    this.$store.commit(mutations.LIST_SELECT, list);
    this.$store.commit(mutations.SITE_SELECT, null);
    return this.load();
  }

  async selectAll() {
    this.$store.commit(mutations.LIST_SELECT, null);
    this.$store.commit(mutations.SITE_SELECT, null);
    return this.load();
  }

  async load() {
    let communications;
    // Site sélectionné
    if (this.site) {
      communications = await this.http.loadCommunicationsForSite(this.site._id);
      document.title = `${this.site.customerId} ${this.site.name} - Communications`;
    } else if (this.list) {
      // Liste sélectionnée
      communications = await this.http.loadCommunicationsForList(this.list._id);
      document.title = `Liste ${this.list.name} - Communications`;
    } else {
      // Toutes
      communications = await this.http.loadCommunications();
      this.resetTitle();
    }
    this.communications = communications;
    // this.$store.commit(mutations.COMMUNICATIONS_SET, communications);
  }

  resetTitle() {
    document.title = 'Communications - MyPlay';
  }
}
