
import Vue from 'vue';
import _ from 'lodash-es';
import Component, { mixins } from 'vue-class-component';
import SiteSearch from '@shared/components/SiteSearch.vue';
import List from '@shared/store/models/List';
import HttpMixin from '@shared/http/http.mixin';
import Mutations from '@shared/store/mutations';

const props = Vue.extend({ props: ['color'] });

@Component({ components: { SiteSearch } })
export default class ListManagerDialog extends mixins(Vue, HttpMixin, props) {
  dialog = false;

  list: List | null = null;

  creating = false;

  loading = false;

  get lists() {
    return this.$store.state.lists;
  }

  itemText(item: List) {
    return `${item.name} (${item.sites.length})`;
  }

  selectAList(list: List) {
    this.creating = false;
    this.list = _.cloneDeep(list);
  }

  create() {
    this.creating = true;
    this.list = {
      name: '',
      sites: [],
    };

    this.$nextTick().then(() => {
      if (this.$refs.name && this.$refs.name instanceof HTMLElement) this.$refs.name.focus();
    });
  }

  reset() {
    this.creating = false;
    this.list = null;
  }

  async save() {
    if (this.list) {
      let list;
      if (this.list._id) {
        list = await this.http.updateList(this.list);
      } else {
        list = await this.http.saveList(this.list);
      }
      this.$store.commit(Mutations.LIST_ADD_OR_UPDATE, list);
    }
    this.dialog = false;
    this.$emit('close');
  }
}
