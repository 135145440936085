export enum SnackType {
  SUCCESS,
  WARNING,
  ERROR,
  INFO,
}

export interface SnackOptions {
  text: string;
  type?: SnackType;
  /**
   * @description Durée pendant laquelle le snack reste affichée, en ms
   */
  timeout?: number;
  color?: string;
  dark?: boolean;
  width?: number;
}
